:root {
  --color-primary: #f27523;
  --color-grey: #474647;
  --color-greylight: #ececec;
}
section {
  position: relative;
  padding-bottom: 100px;
  padding-top:100px;
}
body {
  flex-wrap: wrap;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}
a {
  letter-spacing: 1px;
}
h1 {
  font-weight: 800;
}
h2 {
  font-weight: 600;
}
h3 {
  font-weight: bold;
}
p {
  font-weight: 500;
}
textarea {
  width: 100%;
  padding: 10px;
  color: rgb(59, 59, 59);
}
input {
  width: 100%;
  padding: 10px;
  color: rgb(59, 59, 59);
}
span {
  font-weight: 600;
  color: var(--color-primary) !important;
}
p {
  color: #5f5f5f;
  font-weight: normal;
}
.absoulteNav {
  position: absolute;
  margin-top: 0;
  width: 100%;
}
.centered {
  text-align: center;
  align-items: center;
}
.center-div {
  align-items: center;
}
.header {
  margin-top: 100px;
}
.header p {
  color: #000;
}
.header h1 {
  color: #000;
  font-weight: 900;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.hero {
  height: 100vh;
}
.hero .content-hero {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hero-btn-section {
  display: flex;
}
.card-hero {
  color: #fff;
  padding: 50px 30px 50px 30px;
}
.card-hero h1 {
  font-size: 4rem;
  font-size: clamp(12px, 3vw, 64px);
}
.card-hero p {
  font-size: 20px;
  font-size: 1.5rem;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .hero-btn-section {
    display:flex;
    justify-content:center;
  }
  .card-hero h1 {
    font-size: 40px;
    text-align: center;
  }
  .card-hero p {
    text-align: center;
    font-size: 20px;
    font-size: 1.5rem;
    color: #fff;
  }
  .line-yellow {
    border-left: 0px solid rgb(0, 0, 0, 0) !important;
  }
}
video {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.video-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
}
.controls {
  position: absolute;
  bottom: 10px; /* Ajusta según sea necesario */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 700;
}
.video-button {
  background-color: rgb(255, 255, 255, 0.2);
  border: 0;
  margin: 20px;
  width: 100px;
  height: 10px;
  transition: 0.3s;
  
}
.video-button-active {
  margin-top: 15px;
  background-color: rgb(255, 255, 255, 0.4) !important;
}

.video-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: opacity(0);

  transition: filter 0.5s ease;
}

.video-slide.active {
  filter: opacity(1);
}

.video-slide.next {
  filter: opacity(0);
}

.control-btn {
  margin: 0 5px;
}
.card h3 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: x-large;
}
img {
  height: auto;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .btn-area {
    display: flex;
    flex-direction: column;
  }

  .contact-header {
    display: none;
  }
  .quote-container {
    display: none !important;
  }
}
.btn-area {
  justify-content: center;
}
.btn {
  display: flex;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px;
  font-weight: 800;
  min-height: 50px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 0px;
  align-items: center;
  margin-right: 10px;
}
.btn-about {
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  color: #000;
  margin-right: 10px;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease;
}
.btn-about:hover {
  cursor: pointer;
  border-bottom: 4px solid #fac9a9;
}
.btn-about.selected {
  border-bottom: 4px solid var(--color-primary);
  height: 30px;
}

.primary {
  background: var(--color-primary);
  color: #fff;
  border: 1px solid var(--color-primary);
  transition: 0.3s;
  font-weight: 700 !important;
  font-size: medium;
  border: 2px solid rgb(0,0,0,0) !important;

}
.primary:hover {
  color: var(--color-primary);
  background: #fff;
  transform: translateY(-8%);
  border: 2px solid var(--color-primary) !important;

}

.secondary {
  color: var(--color-primary) !important;
  margin-left: 20px;
  background-color: #fff;
  transition: 0.3s;
  font-weight: 700 !important;
  font-size: medium;
}
.secondary:hover {
  background: var(--color-primary);
  color: #fff !important;
  transform: translateY(-8%);
}

.third {
  color: var(--color-primary) !important;
  transition: 0.3s;
  border: 2px solid var(--color-primary) !important;
  font-weight: 700 !important;
  font-size: medium;
}
.third:hover {
  background: var(--color-primary);
  color: #fff !important;
  transform: translateY(-8%);
}
.btn-navbar{
  color:#fff !important;
}
.btn-navbar:hover{
  color:var(--color-primary) !important;
}
.line-yellow {
  border-left: 6px solid var(--color-primary);
  height: auto;
  margin-left: 0;
}
.pull-right {
  display: flex;
  justify-content: right;
}
.pull-left {
  display: flex;
  justify-content: left;
}

.top-bar {
  font-weight: 400;
  background-color: rgb(0, 0, 70);
  padding: 10px;
  color: #fff;
}

.top-bar svg {
  font-size: 20px;
  margin-right: 5px;
}
.top-bar a:hover {
  cursor: pointer;
}
.card-solution {
  padding: 40px;
  background-color: #fff;
  border: 1px solid rgb(0, 0, 0, 0.05);
  transition: all .3s ease;
  z-index:50;
  height: 100%;
}

.card-solution:hover {
  padding: 40px;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  z-index: 50;
}
.card-solution img {
  width: 80px;
  margin-bottom: 10px;
}
.card-solution h2 {
  text-transform: capitalize !important;
  margin-bottom: 20px;
}
.contact-icon {
  color: var(--color-primary);
  font-size: 2.9rem;
}
.card-solution p {
  margin-bottom: 5px;
  text-wrap: pretty;
}
.services {
  padding-top: 100px;
  padding-bottom: 100px;
}
.card {
  border: 0px solid transparent;
  min-height: 100%;
  box-shadow: 0px 2px 16px 0px rgba(146, 184, 255, 0.3);
}
.card br {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #eaeaea;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.align-center {
  align-items: center;
}

.card-services-container {
  margin-top: 40px !important;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1rem;
}
.image {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  transition: 0.3s;
}

.image:hover {
  filter: brightness(50%);
  transition: 0.3s;
}

.contact-us .contact-card {
  background-color: #fff;
}
.mb-30 {
  margin-bottom: 30px;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.footer {
  background-color: #fff;
  color: #000;
  padding: 50px;
}

.footer-icon {
  height: 30px;
  width: 30px;
  color: #000;
}
.footer .col-sm-4 {
  margin-bottom: 50px;
}
.footer-copy {
  background-color: #f0f0f0;
  padding: 20px;
  color: #000;
  font-family: sans-serif;
}

.mb-20 {
  margin-bottom: 20px;
}
.about {
  margin-top: 100px;
  margin-bottom: 50px;
}
.about p {
  color: #5f5f5f;
  font-weight: normal;
}
.mail-footer p {
  line-break: anywhere;
}
.sections-footer a {
  color: #5f5f5f;
  font-size: large;
  text-decoration: none;
  transition: 0.3s;
  font-weight: 500;
}
.services-footer a {
  color: #5f5f5f;

  font-size: large;
  text-decoration: none;
  transition: 0.3s;
  font-weight: 500;
}
.services-footer a:hover {
  color: var(--color-primary);
  text-decoration: none;
}
.sections-footer a:hover {
  color: var(--color-primary);
  text-decoration: none;
}

.cardSocialMedia {
  font-size: 45px;
  color: #000;
  position: fixed;
  right: 0;
  bottom: 40%;
  padding: 6px;
  background: #fff;
  background-size: 200%;
  text-align: center;
  z-index: 99;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.16);
}
.cardSocialMedia svg {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-bottom: 5px;
}
.cardSocialMedia svg:hover {
  filter: brightness(-80);
  cursor: pointer;
  background-position: right center;
  transform: translate(0%, -6%);
}
.linkedin {
  color: #0077b5;
}
.whatsapp-color {
  color: #25d366;
}
.facebook-color {
  color: #1877f2;
}

.uppercase {
  text-transform: uppercase;
}
.call-action {
  background-color: #400040;
  color: #fff;
  padding: 10px;
  padding-top: 120px;
  padding-bottom: 120px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.call-action h1 {
  font-size: 45px;
  text-transform: uppercase;
}
.band-img-section {
  display: flex;
  gap: 41px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}
.band-img-shape {
  position: absolute;
  min-width: 356px;
  min-height: 439px;
  background-color: var(--color-primary);
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 16px 0px rgba(146, 184, 255, 0.3);
}
.band-img-left {
  background-image: url("../../public/img/about.webp");
  background-size: cover;
  background-position: center;
  height: 400px;
  width: 300px;
  transition: 0.3s;
}
.band-img-left:hover {
  transform: translateY(-2%);
}
.band-img-right {
}
.band-img-2 {
  text-align: center;
  margin-bottom: 47.3px;
  padding-top: 18px;
  background-image: url("../../public/img/about2.jpg");
  background-size: cover;
  background-position: center;
  height: 150px;
  width: 200px;
  transition: 0.3s;
}
.band-img-2:hover {
  transform: translateY(-2%);
}
.band-img-3 {
  background-image: url("../../public/img/about3.jpg");
  background-size: cover;
  background-position: center;
  height: 300px;
  width: 250px;
  transition: 0.3s;
}
.band-img-3:hover {
  transform: translateY(-2%);
}

.card-servicev2 {
  height: 600px;
  min-height: 485px;
  display: flex;
  align-items: end;
  padding: 0 20px 20px 0;
  position: relative;
  margin-bottom: 30px;
  box-shadow: 0px 2px 16px 0px rgba(146, 184, 255, 0.3);
  margin: 10px !important;
}
.card-servicev2 .title {
  background-color: #fff;
  padding: 0px 38px 10px 10px;
  position: absolute;
  left: -10px;
  width: 95%;
  bottom: 20px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 2px 16px 0px rgba(146, 184, 255, 0.3);
}
.card-servicev2 h4 {
  font-weight: 900;
}
.card-servicev2 .icon {
  margin-left: 10px;
  display: inline-flex;
  width: 80px;
  height: 90px;
  
  background-color: var(--color-primary);
  margin-top: -59px;
  margin-bottom: 17px;
  padding: 10px;
}
.card-servicev2 .icon img {
  height: 70px !important;
}
.hidden {
  overflow: hidden;
  max-height: 0px;
  opacity: 0;
  transform: scaleY(1);
  transition: max-height 1s ease 0s, opacity 1s ease 0s, transform 1s ease 0s;
}
.card-servicev2:hover {
  cursor: pointer;
}
.card-servicev2:hover .hidden {
  max-height: 550px;
  opacity: 1;
  transform: scaleY(1);
  transition: max-height 1s ease 0s, opacity 0.3s ease 0s, transform 1s ease 0s;
}
.projects {
  background-color: #f0f0f0;
}
.projects span {
  color: var(--color-primary);
}
.services span {
  color: var(--color-primary);
}

.card-proyect {
  z-index: 1;
  margin-right: -50px;
  align-items: center;
  display: flex;
  margin-bottom: 30px;
  transition: 0.3s;
  min-height: 80px;
  box-shadow: 0px 2px 16px 0px rgba(146, 184, 255, 0.3);
  background-color: #fff;
}
.card-number {
  margin: 0;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  justify-content: center;
  font-weight: 900;
  padding: 10px;
  transition: 0.3s;
  background-color: var(--color-primary);
}
.card-proyect.active {
  background-color: #000;
  color: #fff;
  .card-number {
    background-color: var(--color-primary);
    justify-content: center;
    align-items: center;
    padding: 25px;
  }
}
.card-proyect:hover {
  .card-number {
    padding: 10px;
    background-color: var(--color-primary);
    justify-content: center;
    align-items: center;
  }
  background-color: #000;
  color: #fff;
  cursor: pointer;
}

.card-title {
  padding: 10px;
  margin-left: 20px;
}
.project-img-container {
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.3);
  z-index: 50;
  background-position: center;
  background-size: cover;
  margin-top: -10px;
  height: 550px;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .card-proyect {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .project-img-container {
    margin: 10px;
    width: -webkit-fill-available;
    height: 300px;
  }
}

.logo-inteligen-t img {
  height: 25px;
  width: auto;
}
.card-project-page{
  position:absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  padding: 10px;
  background-color: rgb(0, 0, 0,0.7);
  color: white;
  font-weight: 700;
  transition: all .3s ease;

}
.card-project-page p{
  color: #fff;
}
.card-proyecto-singe{
  transition: all .3s ease;
}
.card-proyecto-singe:hover{
  transform: translateY(-3%);
}
.card-proyecto-singe:hover .hidden{
  max-height: 550px;
  opacity: 1;
  transform: scaleY(1);
  transition: max-height 1s ease 0s, opacity 0.3s ease 0s, transform 1s ease 0s;
}
.img-project-detail{
  background-size: cover;
  background-position: center;
  height: 300px;
  transition: all .3s ease;

}
.img-project-detail:hover{
  cursor: pointer;
  transform: translateY(-3%);
}
.img-detail{
  background-color:rgb(0,0,0,0.0);
  width:100%;
  height:100%;
  transition: all .3s ease;
  color:rgb(255,255,255,0);
  font-size: 40px;

}
.img-detail:hover{
  width:100%;
  height:100%;
  background-color:rgb(0,0,0,0.7);
  color:rgb(255,255,255);
  font-size: 40px;
}

.fullscreen-mode {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullscreen-mode img {
  max-width: 70%;
  max-height: 70%;
  object-fit: contain;
  cursor: pointer;
}

.toggle-fullscreen {
  position: absolute; 
  top: 20px;
  right: 20px;
  z-index: 1001;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
  color: #000;
  font-size: 14px;
  cursor: pointer;
}

.toggle-fullscreen:hover {
  background-color: #f0f0f0;
}
.error-msj{
  color:red;
}
.img_container_contact{
  display: flex;
  align-items: center;
  height: auto;
}
.img_center_div{
  max-height: 100%;
  margin: auto;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.3);
}