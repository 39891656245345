.navbar {
    --bs-navbar-padding-y: 0rem !important;
    height: 80px;
    z-index: 99999;
    animation: slideOut 0.8s ease forwards;
    width:100%;
    background-color:#fff;
    box-shadow: 0px 5px 30px -10px rgba(0, 0, 0, 0.25);
  }
  
  .navbar-nosticky .content{
    padding:10px 20px 10px 20px;
    border-radius:5px;
  }
  .content a{
    color:#fff;
  }
  .navbar a {
    color: #212529;
    text-transform: uppercase;
    font-weight: 600;
    transition: 0.3s;
    font-size: 15px;
    text-decoration: none;
  }
  
  .navbar .navbar-content a:hover {
    cursor: pointer;
    color: var(--color-primary);
  }
  
  .navbar.sticky {
    position: fixed;
    top: 0;
    z-index: 99999;
    width: 100%;
    
  }
  .navbar.sticky.absoulteNav {
    margin-top: 0px !important;
  }
  .navbar-content {
    width: 100%;
  }
  .navbar-content.content {
    margin-left: auto;
  }

  .sticky {
    width: 100%;
    background-color: #fff;
    animation: slideIn 0.8s ease forwards;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }
  .mobile-menu-toggle {
    display: none;
  }
  
  .navbar-display {
    display: flex !important;
    align-items: center !important;
  }
  .navbar-content-responsive{
    width:100%;
    margin-left:200px;
    margin-right:200px;
    justify-content: space-between !important;
    display: flex !important;
  }

  @media screen and (max-width: 1399px) {
    .navbar-content-responsive{
      width:100%;
      margin-left:120px;
      margin-right:120px;
    }
  }
  @media screen and (max-width: 1199px) {
    .navbar-content-responsive{
      width:100%;
      margin-left:80px;
      margin-right:80px;
    }
  }
  @media screen and (max-width: 991px) {
    .navbar-content-responsive{
      width:100%;
      margin-left:120px;
      margin-right:120px;
      justify-content:left;
      display:grid !important;
      text-align: center;
      padding: 10px;
    }
    .btn-navbar{
      display: none !important;
    }
    .navbar {
      background-color: #fff;
    }
    .navbar-nosticky{
      margin-top:0px;    
    }
    .navbar-nosticky .content {
      box-shadow:none;
    }
    .navbar-content {
      display: none;
      flex-direction: row;
      align-items: center;
      position: absolute;
      top: 80px;
      left: 0;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
      z-index: 999;
      transition: transform 0.3s ease-in-out;
      transform: translateY(-100%);
    }
  
    .navbar-content.show {
      transform: translateY(0);
      display: flex;
    }
  
    .navbar-content a {
      margin: 15px 0;
    }
    .content-responsive {
      display: flex !important;
      flex-direction: column !important;
      text-align: center;
    }
  
    .mobile-menu-toggle {
      display: block;
      cursor: pointer;
      margin-right: 20px;
    }
  
    .bar {
      width: 25px;
      height: 3px;
      background-color: #000;
      margin: 5px 0;
      transition: transform 0.3s, opacity 0.3s;
    }
  
    .bar.open:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }
  
    .bar.open:nth-child(2) {
      opacity: 0;
    }
  
    .bar.open:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
    .logo-navbar-responsive {
      display: block !important;
    }
    .logo-navbar {
      display: none !important;
    }
  }
  .logo-navbar-responsive {
    display: none;
  }
  .logo-navbar {
    display: block;
  }
  .logo-container {
    width: 80% !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .logo-container {
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }